<template>
  <div id="dashboard">
    <el-container>
      <el-header
        ><el-row justify="space-between" align="middle" type="flex"
          ><el-col :span="6"
            >ACCUrate Service
            <small
              ><a href="/#/settings">{{ version }}</a></small
            ></el-col
          >
          <el-col :span="8">
            <el-menu
              router
              mode="horizontal"
              text-color="#7ba1ed"
              active-text-color="#409EFF"
              default-active="/"
            >
              <el-menu-item index="/">
                <i class="el-icon-menu"></i>
                <span slot="title">分析</span>
              </el-menu-item>
              <el-menu-item index="/logs">
                <i class="el-icon-menu"></i>
                <span slot="title">日誌</span>
              </el-menu-item>
              <el-submenu index="/tools">
                <template slot="title">
                  <i class="el-icon-location"></i>
                  <span slot="title">工具</span>
                </template>
                <el-menu-item index="/tools/librarycalculator"
                  ><i class="el-icon-menu"></i>
                  <span slot="title">計算Library</span></el-menu-item
                >
              </el-submenu>
              <el-menu-item index="/settings">
                <i class="el-icon-setting"></i>
                <span slot="title">設定</span>
              </el-menu-item>
            </el-menu>
          </el-col>
          <el-col :span="4">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ userAccount
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-user"
                  >單位： {{ userOrg }}
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-switch-button" command="logout"
                  >登出</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown></el-col
          ></el-row
        ></el-header
      >
      <el-alert
        v-if="isDev"
        title="此為測試版本，資料內容獨立於正式版本。正式版本請至 https://accurate.accuinbio.com"
        type="warning"
        show-icon
        center
        :closable="false"
      ></el-alert>
      <el-main><router-view /></el-main>
    </el-container>
  </div>
</template>
<script>
import { db, auth } from "@/firebase";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot, doc } from "firebase/firestore";

export default {
  data() {
    return {
      version: `v1.0`,
      isDev: process.env.NODE_ENV === "development",
      authenticatdUser: null,
      authorizedUser: null,
      org: null,
      unsub: [],
    };
  },
  mounted() {},
  methods: {
    async handleCommand(command) {
      if (command === "logout") {
        try {
          await signOut(auth);
          this.$router.push({ name: "login" }).catch(() => {});
        } catch (error) {
          console.error("登出失败", error);
        }
      }
    },
  },
  computed: {
    userAccount: function () {
      return this.authenticatdUser && this.authenticatdUser.email
        ? this.authenticatdUser.email
        : "";
    },
    userOrg: function () {
      return this.org && this.org.name ? this.org.name : "";
    },
  },
  created() {
    this.authenticatdUser = auth.currentUser;
    this.unsub.push(
      onAuthStateChanged(auth, (user) => {
        this.authenticatdUser = user;
        if (!user) {
          this.$router.push({ name: "login" }).catch(() => {});
        }
      })
    );
    this.unsub.push(
      onSnapshot(collection(db, "app-user"), (snapshot) => {
        let isSignup = false;
        snapshot.forEach((docSnapshot) => {
          if (docSnapshot.id === this.authenticatdUser.uid) {
            isSignup = true;
            this.authorizedUser = docSnapshot.data();
            this.unsub.push(
              onSnapshot(
                doc(db, `app-organization/${this.authorizedUser.orgID}`),
                (doc) => {
                  this.org = doc.data();
                }
              )
            );
          }
        });
        if (!isSignup) {
          this.$router.push({ name: "signup" }).catch(() => {});
        }
      })
    );
  },
};
</script>
<style scoped>
/** disabled a href style */
a {
  text-decoration: none;
  color: #7ba1ed;
}
</style>
