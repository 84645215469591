import Vue from "vue";
import VueRouter from "vue-router";
import MainView from "../views/MainView.vue";
import { auth } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: MainView,
    children: [
      {
        path: "",
        name: "datatable",
        component: () => import("../components/DataTableView.vue"),
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("../components/SettingView.vue"),
      },
      {
        path: "tools/functionstesting",
        name: "functionstesting",
        component: () => import("../components/tools/FunctionsTestingView.vue"),
      },
      {
        path: "tools/generatedata",
        name: "generatedata",
        component: () => import("../components/tools/GenerateDataView.vue"),
      },
      {
        path: "tools/librarycalculator",
        name: "librarycalculator",
        component: () =>
          import("../components/tools/LibraryCalculatorView.vue"),
      },
      {
        path: "logs",
        name: "logs",
        component: () => import("../components/LogView.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../components/LoginView.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../components/SignupView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

// 當頁面剛載入時，auth.currentUser 會是 null
// 無法使用 auth.currentUser 來判斷是否登入
// 使用 onAuthStateChanged 來讀取當前使用者並結束 Promise
const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      auth,
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach((to, from, next) => {
  const user = getCurrentUser();
  if (to.name !== "login" && to.name !== "signup" && !user) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
