import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    editableTabs: [],
    editableTabsValue: "",
  },
  getters: {},
  mutations: {
    addEditableTabs(state, tabs) {
      state.editableTabs.push(tabs);
    },
    removeEditableTabs(state, idx) {
      state.editableTabs.splice(idx, 1);
    },
    setEditableTabsValue(state, value) {
      state.editableTabsValue = value;
    },
  },
  actions: {},
  modules: {},
});
