<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {};
</script>
<style>
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微軟正黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
.el-header,
.el-footer {
  color: #7d7777;
  border: 1px solid #ebeef5;
  line-height: 30px;
}
.el-menu {
  border-bottom: 0 !important;
  background-color: transparent !important;
}
</style>
